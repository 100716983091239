import React from 'react';
import './App.css';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import MainContent from './components/MainContent';
import AboutMe from './components/AboutMe';
import Skills from './components/Skills';
import Projects from './components/Projects';

export default function App() {
  return (
    <div id="App">
      <div id="wrapper">
        <BrowserRouter>
          <Switch>
            <Route path="/about-me" component={AboutMe} />
            <Route path="/projects" component={Projects} />
            <Route path="/skills" component={Skills} />
            <Route path="/" component={MainContent} />
          </Switch>
        </BrowserRouter>
      </div>
    </div>
  );
}