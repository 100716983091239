import React from 'react';
import NameAndTitle from './NameAndTitle';
import Nav from './Nav';
import Contact from './Contact';
import Footer from './Footer';
import ProgressiveImage from 'react-progressive-image-loading';
const smUrl = 'https://res.cloudinary.com/lenwinkler/image/upload/v1612228320/profilePic-small_g5ya0e.png';
const lgUrl = 'https://res.cloudinary.com/lenwinkler/image/upload/v1612228320/profilePic_cuwlgc.jpg'

const MainContent = props => {
    return (
        <>
        <div id="main-content-container" data-testid="main-content">
            <ProgressiveImage
                delay={2000}
                preview={smUrl}
                src={lgUrl}
                render={(src, style) => <img src={src} id='main-pic' alt='Len Winkler' />}
            />
            <div id="title-nav-contact-container">
                <NameAndTitle />
                <Nav props={props}/>
                <Contact />
            </div>
        </div>
        <Footer />
        </>
    )
};

export default MainContent;