import React from 'react';

const NameAndTitle = () => {
    return (
        <div id="name-title-div" data-testid="name-and-title">
            <h1 id="name">LEN WINKLER</h1>
            <h2 id="title">FULL STACK DEVELOPER</h2>
        </div>
    )
};

export default NameAndTitle;