import React from 'react';
import Footer from './Footer';
import ProgressiveImage from 'react-progressive-image-loading';
const smUrl = 'https://res.cloudinary.com/lenwinkler/image/upload/v1612228320/skills3-small_hqux1p.png';
const lgUrl = 'https://res.cloudinary.com/lenwinkler/image/upload/v1612228320/skills3_gyebgl.jpg'

const Skills = props => {
    return (
        <>
        <div id="skills-container" data-testid='skills'>
            <ProgressiveImage
                delay={2000}
                preview={smUrl}
                src={lgUrl}
                render={(src, style) => <img src={src} id='skills-pic' alt='A keyboard' />}
            />
            <div id="skills-content">
                <button id="skills-back-button" onClick={() => props.history.goBack()}>{`${String.fromCharCode(8592)} BACK`}</button>
                <h1 id="skills-title">SKILLS</h1>
                <div id="skills-list-container" data-testid='skills-container'>
                    <ul id="skills-list-1">
                        <li>JAVASCRIPT</li>
                        <li>NODE</li>
                        <li>PYTHON</li>
                        <li>CSS</li>
                    </ul>
                    <ul id="skills-list-2">
                        <li>REACT</li>
                        <li>EXPRESS</li>
                        <li>HTML</li>
                        <li>SASS</li>
                    </ul>
                </div>
            </div>
        </div>
        <Footer />
        </>
    )
};

export default Skills;