import React from 'react';
import Footer from './Footer';
import ProgressiveImage from 'react-progressive-image-loading';
const smUrl = 'https://res.cloudinary.com/lenwinkler/image/upload/v1612228320/gitProfilePic-small_qmwgty.png';
const lgUrl = 'https://res.cloudinary.com/lenwinkler/image/upload/v1612228320/gitProfilePic_or0vtc.png';

const AboutMe = props => {
    return (
        <>
        <div data-testid='about-me' id="about-me-container">
            <ProgressiveImage
                delay={2000}
                preview={smUrl}
                src={lgUrl}
                render={(src, style) => <img src={src} id='about-me-pic' alt='Len Winkler' />}
            />
            <div id="about-me-content">
                <button id="about-back-button" onClick={() => props.history.goBack()}>{`${String.fromCharCode(8592)} BACK`}</button>
                <h1 id="about-me-title">ABOUT ME</h1>
                <p className="about-me-text">
                    Before learning to code, I worked as a Quality Assurance Inspector
                    in the manufacturing industry. A decade of QA experience has helped me develop an eye for detail, an aptitude
                    for multitasking and decision making in high pressure situations, and the
                    determination to see a difficult problem all the way to its resolution. 
                    A desire for growth and a hunger for a sense of fulfillment led me to 
                    the field of software development.
                </p>
                <p className="about-me-text">
                    I have a passion for technology and learning.
                    I want to build interesting things, solve challenging problems, grow as a person,
                    and leave the world better than I found it. 
                </p>
            </div>
        </div>
        <Footer />
        </>
    )
};

export default AboutMe;