import React from 'react';

const NavBar = props => {
    const { history } = props.props;
    return(
        <div id="NavBar-Div" data-testid='Nav'>
            <button data-testid='about-me-button' onClick={() => history.push("/about-me")}>About Me</button>
            <div className="vert-line"></div>
            <button data-testid='skills-button' onClick={() => history.push("/skills")}>Skills</button>
            <div className="vert-line"></div>
            <button data-testid='projects-button' onClick={() => history.push("/projects")}>Projects</button>
        </div>
    )
};

export default NavBar;