import React from 'react';

const Contact = () => {
    return (
        <div id="contact-icon-container" data-testid='contact'>
            <a href="https://www.linkedin.com/in/lenwinkler/" target="_blank" rel="noopener noreferrer" className="contact-icon" data-testid='linkedin-button'><i className="fab fa-linkedin"></i></a>
            <a href="mailto:lenwinkler86@gmail.com" className="contact-icon" data-testid='email-button'><i className="fas fa-envelope"></i></a>
            <a href="https://www.github.com/lenwinkler" target="_blank" rel="noopener noreferrer" className="contact-icon" data-testid='github-button'> <i className="fab fa-github-square"></i></a>
        </div>
    )
};

export default Contact;