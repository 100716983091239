import React from 'react';
import Footer from './Footer';
import ProgressiveImage from 'react-progressive-image-loading';
const smSautiUrl = 'https://res.cloudinary.com/lenwinkler/image/upload/v1612228320/Sauti_screen-small_k28u6z.png';
const lgSautiUrl = 'https://res.cloudinary.com/lenwinkler/image/upload/v1612228321/Sauti_screen_fcukx1.png';
const smShortenerUrl = 'https://res.cloudinary.com/lenwinkler/image/upload/v1624944385/url_shortener-small_wwc81k.png';
const lgShortenerUrl = 'https://res.cloudinary.com/lenwinkler/image/upload/v1624944021/url_shortener_cowsiw.png';
const smRomNomUrl = 'https://res.cloudinary.com/lenwinkler/image/upload/v1624944818/rom_nom-small_wcvgiu.png';
const lgRomNomUrl = 'https://res.cloudinary.com/lenwinkler/image/upload/v1624944818/rom_nom_ojwkta.png';

const Projects = props => {
    return (
        <div id="projects-container" data-testid='projects'>
            <button id="projects-back-button" onClick={() => props.history.goBack()}>{`${String.fromCharCode(8592)} BACK`}</button>
            <h1 id="projects-title" autofocus>PROJECTS</h1>
                <div id="card-container">
                    <div className="project-card" data-testid="card">
                        <ProgressiveImage
                            delay={2000}
                            preview={smShortenerUrl}
                            src={lgShortenerUrl}
                            render={(src, style) => <img src={src} className='project-pic' alt='Code for Url Shortener backend' />}
                        />
                        <div className="project-info">
                            <a href="https://github.com/LenWinkler/url-shortener-backend" target="_blank" rel="noopener noreferrer" className="project-name">Url Shortener Backend</a>
                            <div className="project-stack">
                                <i className="icon-python" />
                                <i className="icon-django" />
                            </div>
                            <p className="project-desc">
                            Backend for an app which allows users to register an account and create shortened urls. 
                            Built with Django Rest Framework and using DRF's token authentication. I don't know how 
                            a URL shortener actually works so this was my attempt to reverse engineer the basic 
                            functionality of one.
                            </p>
                            <ul className="project-desc-list">
                                <li className="project-bullet">Designed custom models and serializers for accounts and urls</li>
                                <li className="project-bullet">Built endpoints to allow CRUD operations</li>
                                <li className="project-bullet">Implemented REST Framework's token authentication</li>
                                <li className="project-bullet">Developed unit tests</li>
                            </ul>
                        </div>
                    </div>

                    <div className="project-card" data-testid="card">
                        <ProgressiveImage
                            delay={2000}
                            preview={smSautiUrl}
                            src={lgSautiUrl}
                            render={(src, style) => <img src={src} className='project-pic' alt='Sauti Databank app' />}
                        />
                        <div className="project-info">
                            <a href="https://sauti.now.sh/" target="_blank" rel="noopener noreferrer" className="project-name">SAUTI DATABANK</a>
                            <div className="project-stack">
                                <i className="icon-react" />
                                <i className="icon-node" />
                                <i className="icon-graphql" />
                                <i className="icon-css3" />
                            </div>
                            <p className="project-desc">
                                Sauti Databank is a web app that allows researchers and legislators to easily 
                                access data about cross-border traders in Uganda, Rwanda, and Kenya. The goal 
                                of the app is to help the lawmakers in these countries write policies that 
                                better serve the needs of all their people, including the cross-border traders 
                                who have mostly been invisible to them due to a lack of data.
                            </p>
                            <ul className="project-desc-list">
                                <li className="project-bullet">Functioned as a Full Stack Engineer on a remote team</li>
                                <li className="project-bullet">Improved and extended the UI using React and CSS</li>
                                <li className="project-bullet">Collaborated with team to refactor app from REST API to GraphQL</li>
                                <li className="project-bullet">Added ability to download data as a CSV file</li>
                            </ul>
                        </div>
                    </div>

                    <div className="project-card" data-testid="card">
                        <ProgressiveImage
                            delay={2000}
                            preview={smRomNomUrl}
                            src={lgRomNomUrl}
                            render={(src, style) => <img src={src} className='project-pic' alt='Rom Nom app' />}
                        />
                        <div className="project-info">
                            <a href="https://github.com/LenWinkler/Rom-nom" target="_blank" rel="noopener noreferrer" className="project-name">Rom Nom</a>
                            <div className="project-stack">
                                <i className="icon-python" />
                                <i className="icon-icons8-selenium" />
                            </div>
                            <p className="project-desc">
                            Python CLI app that automates the downloading, unzipping, and moving of rom files from romsmania.
                            </p>
                            <ul className="project-desc-list">
                                <li className="project-bullet">Utilized Selenium to create and control a Chrome browser instance to download files</li>
                                <li className="project-bullet">Used Python3 to unzip files, create an inventory, and move unzipped files</li>
                                <li className="project-bullet">Created a system to save progress using text files</li>
                            </ul>
                        </div>
                    </div>
                </div>
            <Footer />
        </div>
    )
};

export default Projects;