import React from 'react';

const Footer = () => {
    return (
        <footer data-testid='footer'>
            <p>© 2020-21 Len Winkler</p>
        </footer>
    )
};

export default Footer;